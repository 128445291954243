import styled from 'styled-components';
import { HTMLAttributes, ReactNode } from 'react';

import { colors } from '/css';

enum Sizes {
    MEDIUM = 'medium',
    SMALL = 'small',
}

type Size = `${Sizes}`;

interface Props extends HTMLAttributes<HTMLElement> {
    size?: Size;
    children: ReactNode;
}

const Description = ({ size = Sizes.SMALL, children, ...rest }: Props) => (
    <Wrapper size={size} {...rest}>
        {children}
    </Wrapper>
);

export default Description;

const Wrapper = styled.div<{ size: Size }>`
    font-size: ${(p) => (p.size === 'medium' ? '17' : '15')}px;
    line-height: 1.4;
    color: ${colors.grey6};
    font-family: var(--font-medium);
`;
