import ConfiguredRoof from '/modules/solar/types/ConfiguredRoofType';
import { ReactNode } from 'react';
import Image from '/types/imageType';
import { TotalPriceType } from '/types/priceType';
import FrontImageType from '/front/types/frontImageType';

export enum JobDestinations {
    PROJECT = 'project',
    OFFER = 'offer',
    CART = 'cart',
}
export interface Answer {
    questionId: string;
    questionType: QuestionType | RoofQuestionType;
    answerId: string | string[];
    answerValue: number | string | string[] | Image[];
    productId?: number | number[];
}

export type Answers = Answer[];

export type RoofAnswers = {
    selectedRoofs: ConfiguredRoof[];
};

export type Material = {
    articleDescriptions: {
        description: string;
    };
    articleNo: string;
    brandName: string;
    ean: string;
    friendlyName: string;
    name: string;
    optional: boolean;
    picture: {
        url?: string;
        uRL?: string;
    }[];
    price: {
        spotonPrice?: string;
        customerPrice: number;
        discountGroup: number;
        discountGroup2: number;
        price: number;
        salesPacking: number;
        salesPackingUnit: string;
        unit: string;
        vat: number;
    };
    quantity: number;
    ranking: number;
    soldBy: string;
    isProduct?: boolean;
    lastUpdated?: string;
};

export interface Choice {
    id: string;
    description: string;
    effects: Effect[];
    label: string;
    value: string;
    disabled?: boolean;
    imageUrl?: string;
    imageAlt?: string;
    productPage?: number;
    solarPanelWattage?: number;
    stockInfo?: ReactNode;
    stock?: number;
    stockCheck?: any; // This should be properly typed, no idea what it is
}

export interface ProductSelectorChoice extends Choice {
    material: Material;
}

export type ChoiceProperty = keyof Choice;

export interface Effect {
    property:
        | ''
        | 'totalPrice'
        | 'materialList'
        | 'optionalQuestion'
        | 'workHours';
    operator:
        | ''
        | 'add'
        | 'subtract'
        | 'addPercent'
        | 'subtractPercent'
        | 'multiply';
    value: string | number;
    collapsed: boolean;
    conditonalOperator: '' | '=' | '<' | '>'; // used in effects on number questions sonly
    conditonalValue: number; // used in effects on number questions sonly
    subtractQuestionDefaultValueFromAnswerValue: boolean; // used in effects on number questions sonly
    materialListEffectedMaterial: string | Material; // used for material list effects only
    materialListEffectType:
        | ''
        | 'increaseQuantity'
        | 'decreaseQuantity'
        | 'setQuantity'
        | 'multiplyQuantity'
        | 'setSpotonPrice'
        | 'quantityPriceMap'
        | 'addMaterial'
        | 'removeMaterial'; // used for material list effects only
    materialListEffectQuantity: number; // used for material list effects only
    materialListEffectNumberQuestion: string; // question id used for material list effects only
    materialListQuantityEffectSource: 'fixedNumber' | 'numericQuestion';
    materialListEffectNumberQuestionAnswerModification:
        | 'none'
        | 'add'
        | 'subtract'
        | 'multiply'
        | 'divide';
    materialListEffectNumberQuestionAnswerModificationNumber: number;
    effectHasAdvancedConditions: boolean;
    effectAdvancedConditions:
        | null
        | {
              questionId: string;
              answerId: string;
          }[][];
    effectHasSolarConditions?: boolean;
    effectSolarConditions?: null | {
        panelOrientation: '' | 'horisontal' | 'vertical';
    };
}

export enum QuestionType {
    RADIO = 'radio',
    PRODUCT_SELECTOR = 'product selector',
    CHECKBOX = 'checkbox',
    FILE_UPLOAD = 'file upload',
    NUMBER = 'number',
}

export const questionTypes = [
    QuestionType.RADIO,
    QuestionType.NUMBER,
    QuestionType.FILE_UPLOAD,
    QuestionType.CHECKBOX,
    QuestionType.PRODUCT_SELECTOR,
];

export enum RoofQuestionType {
    PANEL = 'panelType',
    ROOF = 'roofType',
    PANEL_QUANTITY = 'panelQty',
}

export interface Question {
    type: QuestionType;
    id: string;
    title: string;
    description: string;
    defaultValue: string | string[] | number;
    choices: Choice[];
    effects: Effect[];
    helpImages: any[];
    helpText: string;
    fileLimit?: number;
    maxValue?: number;
    minValue?: number;
    optional?: boolean;
    answerLimit?: number;
    theme?: 'slider' | 'ticker';
    usedForRoofConfiguration?: boolean;
    usedForTotalWattageEffects?: boolean;
    usedForBuildingWattageEffects?: boolean;
    usedForPerBuildingEffects?: boolean;
    usedForPerRoofAreaEffects?: boolean;
    roofQuestionType?: RoofQuestionType;
    label?: string;
    visible: boolean;
    reveals: string[];
}

export type QuestionProperty = keyof Question;

export interface ChoiceQuestionType extends Question {
    type:
        | QuestionType.RADIO
        | QuestionType.CHECKBOX
        | QuestionType.PRODUCT_SELECTOR;
}

export enum ProductSelectorQuestionPricingType {
    SYNCED = 'Synced',
    MANUAL = 'Manual',
}

export interface ProductSelectorQuestionType extends Question {
    type: QuestionType.PRODUCT_SELECTOR;
    importList: string;
    priceMultiplier: number;
    choices: ProductSelectorChoice[];
    manualPrices: boolean;
}

export type ChoiceQuestionProperty = keyof ChoiceQuestionType;

interface Seo {
    description?: string;
    image?: string;
    keywords?: string;
    title?: string;
}

export interface JobCategory {
    name: string;
    id: number;
    icon: FrontImageType;
}

export interface JobInfo {
    additionalElectricianInfo?: any; //rich text
    description?: string;
    descriptionHeading?: string;
    disabledWhenUpselling?: boolean;
    images: { url: string }[];
    importantNote?: string;
    popular: boolean;
    seo: Seo;
    tips?: string;
    title?: string;
    integrationJob?: boolean;
    badge: {
        newJob: boolean;
        brandImage: string;
    };
    enovaSupport: EnovaSupport;
}

export interface EnovaSupport {
    enabled: boolean;
    requiredJobs: number[];
    description: string;
}

export interface JobTypeVersion {
    id: number;
    defaultMaterials: Material[]; // default materials set in "materials" tab of configurator
    potentialMaterials: Material[]; // All materials that can potentially be used in this job (from all effects)
    materialsIncludedOnReceipt: string[];
    name: string;
    questions: Question[];
    slug: string;
    workHours: number;
    info: JobInfo;
    additionalCost: number;
    categories: JobCategory[];
    image: string;
    notes: string;
    settings: {
        disablePrepayments?: boolean;
    };
}

export interface TemplateVersion {
    createdAt: string;
    createdBy: string;
    id: number;
    isActiveVersion: boolean;
    notes: string;
    released: boolean;
    releasedAt: string;
}

interface Template {
    jobTypeId: number;
    jobTypeVersionId: number;
    materials: Material[];
    materialsEdited: boolean;
    editedMaterialList: Material[] | null;
    expiredMaterials: string[];
    vismaArticleNumber: string | null;
    workHours: number;
    totals: TotalPriceType;
    versions: TemplateVersion[];
    answers: Answers;
    integrationJob?: boolean;
}

export enum JobTemplateType {
    ELECTRICIAN = 'ELECTRICIAN',
    SOLAR = 'SOLAR',
    HEAT_PUMP = 'HEAT_PUMP',
}

export interface JobTemplate extends Template {
    typeKey: JobTemplateType;
    published: boolean;
    order: number;
    jobTypeVersion: JobTypeVersion;
    images: Image[];
    commentForElectrician: string | null;
    name?: string;
    relatedJobs?: JobTemplate[];
    //"revision_required": false,
    //"cancel_reason": null,
    //"cancelled": false,
    //"roof_answers": null,
    //"meta_data": null,
}

interface SolarJobInfo {
    importantNote?: string | null;
    seo?: Seo;
}

interface SolarJobTypeVersion extends Omit<JobTypeVersion, 'info'> {
    info: SolarJobInfo;
}

export interface SolarJobTemplate extends Template {
    typeKey: 'SOLAR';
    jobTypeVersion: SolarJobTypeVersion;
}

export interface CmsCompatibilityStatus {
    addedProducts: number[];
    removedProducts: number[];
    pagesWithJobModule: Array<{
        id: number;
        path: string;
        slug: string;
        title: string;
        _type: string;
    }>;
}

export interface RelatedJob {
    jobTypeId: number;
    jobTypeVersionId: number;
    jobTypeVersion: Pick<JobTypeVersion, 'image' | 'name' | 'slug' | 'info'>;
    totals: Omit<TotalPriceType, 'materialCost'>;
    typeKey: JobTemplateType;
    workHours: number;
    isEnovaJob?: boolean;
}
