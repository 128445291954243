import { create } from 'zustand';
import { useInitialState } from './store.helpers';
import { persist } from 'zustand/middleware';
import CartType from '/types/cartType';
import { CompanyInfo } from '/types/projectType';
import { JobTemplate, JobTemplateType } from '/types/jobTemplateTypes';

interface OrderStoreState {
    cart: CartType | null;
    inspectionDateTime: string;
    inspectionTopic: JobTemplateType[];
    postCode: string;
    postCodePlace: string;
    postCodeValid: boolean | null;
    postCodeSupportedJobTypes: JobTemplate['typeKey'][];
    postCodeNotificationRequested: boolean;
    sellerDescription: string | null;
    contactInformation: {
        address: string;
        additionalInfo: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        phoneVerificationToken: string;
        company: CompanyInfo | null;
    };
}

export const initialOrderCompanyInformation = {
    id: 0,
    name: '',
    organizationNumber: '',
    address: '',
    postArea: '',
    postCode: '',
};

export const initialOrderContactInformation = {
    address: '',
    additionalInfo: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    phoneVerificationToken: '',
    company: null,
};

const initialState: OrderStoreState = {
    cart: null,
    inspectionDateTime: '',
    inspectionTopic: [],
    postCode: '',
    postCodePlace: '',
    postCodeValid: false,
    postCodeNotificationRequested: false,
    postCodeSupportedJobTypes: [],
    sellerDescription: null,
    contactInformation: initialOrderContactInformation,
};

interface OrderStoreStateMutators {
    setOrderCart: (cart: CartType) => void;
    clearOrderCart: () => void;
    setOrderInspectionDateTime: (inspectionDateTime: string) => void;
    setOrderInspectionTopic: (topic: JobTemplateType[]) => void;
    setOrderPostCode: (postCode: string) => void;
    setOrderPostCodeValidity: (valid: boolean) => void;
    setSellerDescription: (sellerDescription: string) => void;
    setPostCodePlace: (place: string) => void;
    setOrderPostCodeNotificationRequest: (requested: boolean) => void;
    setOrderPostCodeSupportedJobTypes: (
        types: OrderStoreState['postCodeSupportedJobTypes']
    ) => void;
    setOrderContactInformation: (
        contactInformation: OrderStoreState['contactInformation']
    ) => void;
    clearOrderContactInformation: () => void;
    resetOrder: () => void;
}

interface OrderStore extends OrderStoreState, OrderStoreStateMutators {}

export const usePersistedOrderState = create<OrderStore>()(
    persist(
        (set) => ({
            ...initialState,
            setOrderCart: (cart) => set({ cart }),
            clearOrderCart: () => set({ cart: null, sellerDescription: null }),
            setOrderInspectionDateTime: (inspectionDateTime) =>
                set({ inspectionDateTime }),
            setOrderInspectionTopic: (topic) => set({ inspectionTopic: topic }),
            setOrderPostCode: (postCode) => set({ postCode }),
            setOrderPostCodeValidity: (valid) => set({ postCodeValid: valid }),
            setSellerDescription: (sellerDescription) =>
                set({ sellerDescription }),
            setPostCodePlace: (place) => set({ postCodePlace: place }),
            setOrderPostCodeNotificationRequest: (requested) =>
                set({ postCodeNotificationRequested: requested }),
            setOrderPostCodeSupportedJobTypes: (types) =>
                set({ postCodeSupportedJobTypes: types }),
            setOrderContactInformation: (contactInformation) =>
                set({ contactInformation }),
            clearOrderContactInformation: () =>
                set({ contactInformation: initialOrderContactInformation }),
            resetOrder: () => set(initialState),
        }),
        {
            name: 'order',
        }
    )
);

export const useOrderState = <T>(selector: (state: OrderStore) => T): T =>
    useInitialState<OrderStore, OrderStoreState>(
        usePersistedOrderState,
        initialState,
        selector
    );
